import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button, Col, Row } from "react-bootstrap"
import Seo from "../layout/seo"

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404 - Không tìm thấy trang" />
      <div className="not-found-page text-center my-auto">
        <Row className="my-auto">
          <Col>
            <StaticImage
              src="../assets/img/404-page-error-concept---an-employee-tired-illustration.png"
              alt="404"
              height={500}
            />
            <p className="not-found-text">Trang không tồn tại</p>
            <Button href="/">Trang chủ</Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NotFoundPage
